var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"d-flex flex-row-reverse",attrs:{"align":"bottom","justify":"bottom"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"}},[_c('span',{staticClass:"headline"},[_vm._v("Permitted Clients For "+_vm._s(_vm.employee.name))]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"notranslate",attrs:{"disabled":_vm.loading,"items":_vm.allClients,"dense":"","outlined":"","solo":"","background-color":"white","label":"Select","filter":_vm.searchFilter,"error-messages":_vm.searchError,"append-icon":"mdi-magnify"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',[_c('editable-avatar',{staticClass:"mr-2",attrs:{"image":data.item.image,"size":20,"editable":false}}),_vm._v(" "+_vm._s(data.item.first_name + (data.item.last_name ? " " + data.item.last_name : "")))],1)]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',[_c('editable-avatar',{attrs:{"image":data.item.image,"size":40,"editable":false}})],1),_c('v-list-item-content',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(data.item.client_id)+" ")]),_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                    data.item.name
                      ? data.item.name
                      : data.item.first_name +
                        (data.item.last_name ? ' ' + data.item.last_name : '')
                  )}})],1),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(_vm.formatPhoneNumber(data.item.phone))}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(data.item.email)}})],1)]}}]),model:{value:(_vm.newClient),callback:function ($$v) {_vm.newClient=$$v},expression:"newClient"}}),_c('v-btn',{staticClass:"ml-5",attrs:{"disabled":!_vm.newClient},on:{"click":_vm.onAddClientToOrganization}},[_vm._v("Add")])],1),_c('v-card-text',{staticClass:"pb-0",staticStyle:{"align-items":"center","display":"flex","flex-direction":"column"}},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"items":_vm.clients,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                  var item = ref.item;
return [_c('v-avatar',{staticClass:"elevation-6 my-3 ml-5"},[_c('v-img',{attrs:{"src":item.image ? item.image : _vm.placeholderImage}})],1)]}},{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"mt-3",staticStyle:{"font-size":"14px","color":"#482684","font-weight":"500"},domProps:{"innerHTML":_vm._s(
                (item.first_name ? item.first_name + ' ' : '') +
                  (item.last_name ? item.last_name : '')
              )}}),_c('div',{staticClass:"mt-1",staticStyle:{"font-size":"14px","color":"#482684"},domProps:{"innerHTML":_vm._s(_vm.formatPhoneNumber(item.phone))}}),_c('div',{staticClass:"mt-1 mb-3",staticStyle:{"font-size":"14px","color":"#482684","font-weight":"400"},domProps:{"innerHTML":_vm._s(item.email)}})]}},{key:"item.client_id",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticStyle:{"font-weight":"600","color":"#482684"},domProps:{"innerHTML":_vm._s(item.client_id)}})]}},{key:"item.address",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticStyle:{"color":"#482684","font-size":"14px"},domProps:{"innerHTML":_vm._s(
                (item.address ? item.address : 'N/A') +
                  (item.address2 ? ', ' + item.address2 : '')
              )}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onGoToIntake(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_vm._v(" Intake information ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteClientFromOrganization(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" Remove access ")])],1)]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onClose}},[_vm._v(" Close ")])],1)],1)],1),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }