<template>
  <v-col align="bottom" justify="bottom" class="d-flex flex-row-reverse">
    <v-dialog v-model="dialog" persistent max-width="700px">
      <v-card>
        <v-card-title
          style="display:flex; flex-direction: row; justify-content:space-between;"
        >
          <span class="headline"
            >Permitted Clients For {{ employee.name }}</span
          >
          <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="d-flex">
          <v-autocomplete
            :disabled="loading"
            :items="allClients"
            dense
            outlined
            solo
            background-color="white"
            label="Select"
            :filter="searchFilter"
            :error-messages="searchError"
            append-icon="mdi-magnify"
            class="notranslate"
            v-model="newClient"
          >
            <template v-slot:selection="data">
              <v-chip>
                <editable-avatar
                  :image="data.item.image"
                  :size="20"
                  :editable="false"
                  class="mr-2"
                />
                {{
                  data.item.first_name +
                    (data.item.last_name ? " " + data.item.last_name : "")
                }}</v-chip
              >
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <editable-avatar
                  :image="data.item.image"
                  :size="40"
                  :editable="false"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <div class="d-flex">
                  <div class="mr-2">
                    {{ data.item.client_id }}
                  </div>
                  <v-list-item-title
                    v-html="
                      data.item.name
                        ? data.item.name
                        : data.item.first_name +
                          (data.item.last_name ? ' ' + data.item.last_name : '')
                    "
                  />
                </div>
                <v-list-item-subtitle
                  v-html="formatPhoneNumber(data.item.phone)"
                />
                <v-list-item-subtitle v-html="data.item.email" />
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-btn
            class="ml-5"
            :disabled="!newClient"
            @click="onAddClientToOrganization"
            >Add</v-btn
          >
        </v-card-text>
        <v-card-text
          class="pb-0"
          style="align-items: center; display: flex; flex-direction: column;"
        >
          <v-data-table
            :items="clients"
            :headers="headers"
            :loading="loading"
            style="width: 100%"
          >
            <template v-slot:[`item.image`]="{ item }">
              <v-avatar class="elevation-6 my-3 ml-5">
                <v-img :src="item.image ? item.image : placeholderImage" />
              </v-avatar>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div
                v-html="
                  (item.first_name ? item.first_name + ' ' : '') +
                    (item.last_name ? item.last_name : '')
                "
                class="mt-3"
                style="font-size: 14px; color:#482684; font-weight: 500;"
              />
              <div
                v-html="formatPhoneNumber(item.phone)"
                class="mt-1"
                style="font-size: 14px; color:#482684"
              />
              <div
                v-html="item.email"
                class="mt-1 mb-3"
                style="font-size: 14px; color:#482684; font-weight: 400;"
              />
            </template>
            <template v-slot:[`item.client_id`]="{ item }">
              <div
                v-html="item.client_id"
                style="font-weight: 600; color: #482684"
              />
            </template>
            <template v-slot:[`item.address`]="{ item }">
              <div
                v-html="
                  (item.address ? item.address : 'N/A') +
                    (item.address2 ? ', ' + item.address2 : '')
                "
                style="color: #482684; font-size: 14px;"
              />
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onGoToIntake(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-information-outline</v-icon></v-btn
                    >
                  </template>
                  Intake information
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="onDeleteClientFromOrganization(item)"
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-close</v-icon></v-btn
                    >
                  </template>
                  Remove access
                </v-tooltip>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ error }}
    </v-snackbar>
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import EditableAvatar from "../core/EditableAvatar.vue";

export default {
  components: { EditableAvatar },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: Object,
    onCloseDialog: {
      type: Function,
    },
  },
  data() {
    return {
      employee: {},
      clients: [],
      allClients: [],
      error: null,
      snackbar: false,
      loading: false,
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Client Id", value: "client_id" },
        { text: "Name", value: "name" },
        { text: "Address", value: "address" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      placeholderImage: require("@/assets/default.jpg"),
      searchError: null,
      newClient: null,
    };
  },
  methods: {
    ...mapActions({
      getEmployeeClients: "partner/getEmployeeClients",
      deleteClientFromOrganization: "partner/deleteClientFromOrganization",
      addClientToOrganization: "partner/addClientToOrganization",
      getClients: "client/fetchAllClients",
    }),
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    onClose() {
      this.loading = false;
      this.error = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    onDeleteClientFromOrganization(item) {
      this.deleteClientFromOrganization({
        _id: this.employee._id,
        client_id: item._id,
      })
        .then((res) => {
          this.clients = this.clients.filter((c) => c._id != res);
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
    onAddClientToOrganization() {
      this.addClientToOrganization({
        _id: this.employee._id,
        client_id: this.newClient._id,
      })
        .then((res) => {
          this.newClient = null;
          for (var i = 0; i < this.clients.length; i++) {
            if (this.clients[i]._id == res._id) {
              return;
            }
          }
          this.clients.push(res);
        })
        .catch((error) => {
          this.newClient = null;
          this.snackbar = true;
          this.error = error.response.data.message;
        });
    },
    searchFilter(item, queryText) {
      const searchText = queryText.toLowerCase();
      // if (
      //   Object.keys(item).some(
      //     (k) => item[k] && `${item[k]}`.toLowerCase().includes(searchText)
      //   )
      // ) {
      //   return true;
      // }
      if (
        `${item.first_name + " " + item.last_name}`
          .toLowerCase()
          .includes(searchText)
      ) {
        return true;
      } else if (`${item.client_id}`.toLowerCase().includes(searchText)) {
        return true;
      } else if (`${item.phone}`.toLowerCase().includes(searchText)) {
        return true;
      } else if (`${item.email}`.toLowerCase().includes(searchText)) {
        return true;
      }
      return false;
    },
    onGoToIntake(item) {
      let routeData = this.$router.resolve({
        name: "intake",
        params: { id: item._id },
      });
      window.open(routeData.href, "_blank");
    },
  },
  mounted() {
    this.employee = { ...this.item };
    this.loading = true;
    this.getEmployeeClients({ _id: this.employee._id })
      .then((res) => {
        this.clients = res;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.loading = true;
    this.getClients()
      .then((clients) => {
        this.allClients = clients;
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.log(error);
      });
  },
};
</script>
<style scoped>
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
.notranslate >>> i {
  transform: none !important;
}
</style>
