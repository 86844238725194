<template>
  <v-container fluid>
    <Menu
      style="position:fixed; height: 50px; z-index: 1;margin-top:-12px; background: #DBC7FF; left: 0; right: 0;"
      :partner="true"
    />
    <div class="pb-5 px-15" style="margin-top: 40px; background: #F4F9FF;">
      <v-card-title>
        <label class="item_title">Partners</label>
      </v-card-title>
      <v-card-subtitle class="mt-2">
        <label class="item_description">
          Organization partners:
        </label>
      </v-card-subtitle>
      <v-data-table
        :headers="headers"
        :items="organizations"
        :items-per-page="15"
        :loading="loading"
        class="elevation-0"
        :custom-sort="customSort"
        :search="search"
        :custom-filter="customFilter"
        show-expand
        :expanded.sync="expanded"
        item-key="_id"
      >
        <template v-slot:[`item.image`]="{ item }">
          <v-avatar class="elevation-6 my-3 ml-5">
            <v-img :src="item.image ? item.image : placeholderImage" />
          </v-avatar>
        </template>
        <template v-slot:[`item.full_name`]="{ item }">
          <div v-html="getFiltered(item.first_name + ' ' + item.last_name)" />
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <div v-html="getFiltered(item.email)" />
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <div v-html="getFiltered(item.type)" />
        </template>
        <template v-slot:[`item.employees`]="{ item }">
          <div v-html="item.employees.length" />
        </template>
        <template v-slot:[`item.address`]="{ item }">
          <div v-html="getFiltered(item.address)" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onShowClients(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-account-eye</v-icon></v-btn
                >
              </template>
              Permitted Clients
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onEditOrganization(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-pencil</v-icon></v-btn
                >
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onAddEmployee(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-plus</v-icon></v-btn
                >
              </template>
              Add Employees
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="onDeleteOrganization(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
              Delete
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  @click="goToOrganizationChat(item)"
                  v-bind="attrs"
                  v-on="on"
                  ><v-icon>mdi-message-outline</v-icon></v-btn
                >
              </template>
              Chat
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.balance`]="{ item }">
          <div v-if="item.type == 'Freezer'">
            {{ item.balance.length == 0 ? 0 : item.balance[0].amount }}
          </div>
        </template>
        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length" class="px-0">
            <v-data-table
              :headers="subHeaders"
              :items="employeeData[item._id]"
              hide-default-footer
              :loading="loadingEmployee[item._id]"
              class="px-3"
            >
              <template v-slot:[`item.image`]="{ item }">
                <v-avatar class="elevation-6 my-3 ml-5">
                  <v-img :src="item.image ? item.image : placeholderImage" />
                </v-avatar>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <div
                  v-html="getFiltered(item.first_name + ' ' + item.last_name)"
                />
              </template>
              <template v-slot:[`item.phone`]="{ item }">
                <div v-html="getFiltered(formatPhoneNumber(item.phone))" />
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onEditEmployee(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                    Edit
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onDeleteEmployee(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </template>
                    Delete
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="onViewHistory(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon
                          >mdi-clipboard-text-clock-outline</v-icon
                        ></v-btn
                      >
                    </template>
                    Intake Access History
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        @click="goToPartnerChat(item)"
                        v-bind="attrs"
                        v-on="on"
                        ><v-icon>mdi-message-outline</v-icon></v-btn
                      >
                    </template>
                    Chat
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </td>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="deleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this partner? This action can not be
          undone.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteDialog = !deleteDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            @click="deleteAction"
            :loading="loading"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <edit-organization-dialog
      :item="selected"
      :dialog="editDialog"
      :onCloseDialog="onCloseDialog"
      v-if="editDialog"
    />
    <employee-dialog
      :dialog="employeeDialog"
      :onCloseDialog="onCloseDialog"
      v-if="employeeDialog"
      :item="selected"
    />
    <permitted-clients-dialog
      :dialog="clientDialog"
      :onCloseDialog="onCloseDialog"
      v-if="clientDialog"
      :item="selected"
    />
    <intake-access-history-dialog
      :dialog="accessHistoryDialog"
      :partner_id="selected._id"
      v-if="selected && accessHistoryDialog"
      :onCloseDialog="onCloseDialog"
    />
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-container>
</template>
<script>
import Menu from "../../components/core/Menu.vue";
import { mapActions, mapGetters } from "vuex";
import EditOrganizationDialog from "../../components/partner/EditOrganizationDialog.vue";
import EmployeeDialog from "../../components/partner/EmployeeDialog.vue";
import PermittedClientsDialog from "../../components/partner/PermittedClientsDialog.vue";
import IntakeAccessHistoryDialog from "../../components/client/IntakeAccessHistoryDialog.vue";
export default {
  components: {
    Menu,
    EditOrganizationDialog,
    EmployeeDialog,
    PermittedClientsDialog,
    IntakeAccessHistoryDialog,
  },
  data() {
    return {
      isSelectingPhoto: false,
      loading: false,
      errorMessage: null,
      snackbar: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
        passwordMatch: () =>
          this.profile.password != this.profile.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      headers: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Type", value: "service" },
        { text: "Address", value: "address" },
        { text: "Employees", value: "employees" },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      subHeaders: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Role", value: "role" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      selected: null,
      placeholderImage: require("@/assets/default.jpg"),
      profile: { email: "" },
      selectedFile: null,
      deleteDialog: false,
      editDialog: false,
      clientDialog: false,
      search: "",
      partners: [],
      organizations: [],
      employeeDialog: false,
      accessHistoryDialog: false,
      expanded: [],
      employeeData: {},
      loadingEmployee: [],
    };
  },
  computed: {
    ...mapGetters({
      allPartners: "partner/getAllPartners",
      allOrganizations: "partner/getAllOrganizations",
      keyword: "auth/getKeyword",
    }),
  },
  methods: {
    ...mapActions({
      fetchEmployees: "partner/fetchEmployees",
      editPartner: "partner/editPartner",
      deletePartner: "partner/deletePartner",
      fetchAllOrganizations: "partner/fetchAllOrganizations",
      addOrganization: "partner/addOrganization",
      editOrganization: "partner/editOrganization",
      deleteOrganization: "partner/deleteOrganization",
      setBalance: "partner/setBalance",
      createNewConversation: "chat/createNewConversation",
    }),
    onImageSelected(file) {
      this.selectedFile = file;
    },
    onChange() {
      this.errorMessage = null;
    },
    onSelectPartner(item) {
      console.log(item);
      // this.$router.push({ name: "partner-details", params: { id: item._id } });
    },
    onEditOrganization(item) {
      this.selected = item;
      this.editDialog = true;
    },
    onShowClients(item) {
      this.selected = item;
      console.log(this.selected);
      this.clientDialog = true;
    },
    onCloseDialog() {
      this.editDialog = false;
      this.employeeDialog = false;
      this.clientDialog = false;
      this.accessHistoryDialog = false;
    },
    onDeleteOrganization(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onIntake() {
      this.$router.push({ name: "intake", params: { id: this.profile._id } });
    },
    onDelete() {
      this.deleteDialog = true;
    },
    deleteAction() {
      this.loading = true;
      this.deleteOrganization(this.selected)
        .then(() => {
          this.loading = false;
          this.selected--;
          this.deleteDialog = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onViewHistory(item) {
      this.selected = item;
      this.accessHistoryDialog = true;
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    customSort(items, index, isDesc) {
      if (index.length == 0 || index[0] === "full_name") {
        items.sort((a, b) => {
          var order = 0;
          var aStr = `${a["first_name"]} ${a["last_name"]}`;
          var bStr = `${b["first_name"]} ${b["last_name"]}`;

          if (aStr < bStr) order = -1;
          else if (aStr > bStr) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      } else {
        items.sort((a, b) => {
          var order = 0;
          if (a[index] < b[index]) order = -1;
          else if (a[index] > b[index]) order = 1;
          if (isDesc[0]) order *= -1;
          return order;
        });
      }

      return items;
    },
    getFiltered(words) {
      if (!words) return "";
      var filterd = this.$options.filters.highlight(words, this.keyword);
      return filterd;
    },
    customFilter(value, search, item) {
      var searchFilter = false;
      if (this.keyword) {
        if (
          (`${item.first_name} ${item.last_name}` &&
            `${item.first_name} ${item.last_name}`
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            item.phone.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.phone &&
            this.formatPhoneNumber(item.phone)
              .toUpperCase()
              .includes(this.keyword.toUpperCase())) ||
          (item.address &&
            item.address.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.address2 &&
            item.address2.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.type &&
            item.type.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.email &&
            item.email.toUpperCase().includes(this.keyword.toUpperCase())) ||
          (item.service &&
            item.service.toUpperCase().includes(this.keyword.toUpperCase()))
        ) {
          searchFilter = true;
        }
      } else {
        searchFilter = true;
      }
      return searchFilter;
    },
    onAddEmployee(item) {
      this.selected = { organization: item._id };
      this.employeeDialog = true;
    },
    onEditEmployee(item) {
      this.selected = { ...item };
      this.employeeDialog = true;
    },
    getEmployeeData(_id) {
      this.loadingEmployee[_id] = true;
      this.fetchEmployees({ _id })
        .then((res) => {
          this.loadingEmployee[_id] = false;
          this.employeeData[_id] = res;
          console.log(res);
          this.employeeData = { ...this.employeeData };
        })
        .catch((error) => {
          this.loadingEmployee[_id] = false;
          console.log(error.response.data.message);
        });
    },
    goToPartnerChat(item) {
      this.createNewConversation({
        user_id: item._id,
        user_type: "Partner",
      })
        .then((res) => {
          this.$router.push({
            name: "chat-details",
            params: { id: res._id },
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    goToOrganizationChat(item) {
      this.createNewConversation({
        user_id: item._id,
        user_type: "Organization",
      })
        .then((res) => {
          this.$router.push({
            name: "chat-details",
            params: { id: res._id },
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
  },
  created() {
    this.loading = true;
    this.fetchAllOrganizations()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
  watch: {
    selected(newValue) {
      if (this.partners.length > newValue) {
        this.profile = this.partners[newValue];
        if (this.profile.balance.length != 0) {
          this.profile.balance = this.profile.balance.map((b) => {
            return { ...b, editing: false };
          });
        }
      }
    },
    keyword(newValue) {
      this.search = newValue;
    },
    expanded(newValue) {
      console.log(newValue);
      for (var i = 0; i < newValue.length; i++) {
        if (!this.employeeData[newValue[i]._id]) {
          this.getEmployeeData(newValue[i]._id);
        }
      }
    },
    allOrganizations(newValue) {
      console.log(this.allOrganizations);
      if (this.$route.query.new) {
        this.organizations = newValue.filter((item) => {
          var startDate = new Date();
          startDate.setDate(startDate.getDate() - 7);
          var creatTime = new Date(item.createdAt);
          if (creatTime < startDate) {
            return false;
          }
          return true;
        });
      } else {
        this.organizations = newValue;
      }
    },
  },
};
</script>
<style scoped>
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-left: 5px;
  padding-top: 10px;
}
.item_description {
  color: #482684;
  /* font-family: 'Poppins-Bold'; */
  font-size: 18px;
  padding-left: 7px;
}
.partner_title {
  color: #f0a000;
  font-family: "Poppins-SemiBold";
  font-size: 25px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
.edit_button {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.profile {
  overflow: visible;
}
.request_description_title {
  color: #7024c4;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
}
.request_description_item {
  color: #482684;
  font-family: "Poppins-SemiBold";
  font-size: 12px;
}
/* .v-text-field >>> input {
    font-size: 10px;
  } */
.v-text-field >>> .v-text-field__details {
  display: none;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}
</style>
